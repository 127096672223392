<template>
  <div class="newsListPage">
    <div class="newsHeader">
      <h2>最新消息</h2>
      <el-autocomplete class="searchInput" v-model="routeKey" :fetch-suggestions="querySearch" placeholder="请输入相关信息进行查询" :trigger-on-focus="false">
        <template #append>
          <el-button icon="el-icon-search" @click="pageSearch"></el-button>
        </template>
      </el-autocomplete>
    </div>
    <div ref="listRef">
      <template v-for="(item,index) in list" :key="index">
        <div class="newsItem">
          <div class="time">
            <div class="day">{{item.day}}</div>
            <div class="month">{{item.month}}</div>
          </div>
          <div class="content">
            <div class="title" @click="routeTo(item)">{{item.title}}</div>
            <!-- <div class="text" @click="routeTo(item)">{{item.text}}</div> -->
          </div>
        </div>
      </template>
      <el-empty v-if="list.length == 0" description="未找到相关信息"></el-empty>
    </div>
    <div class="block">
      <el-pagination @current-change="pageChange" :currentPage="page.page" :page-size="page.limit" background layout="prev, pager, next" :total="page.total">
      </el-pagination>
    </div>
  </div>
</template>
<script lang="ts">
import { ref, defineComponent, watch, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import moment from "moment";
import { study_getContent } from "@axios/index";
import { ElLoading } from "element-plus";
export default defineComponent({
  name: "news",
  setup(props, content) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const routeKey = ref(route.query.key || "");
    const queryList = [
      "新取证报名",
      "继续教育报名",
      "技术提升班报名",
      "检测人员报名",
      "新取证开班",
      "继续教育开班",
      "技术提升班开班",
      "检测人员开班",
      "能力比对"
    ];
    const querySearch = (queryString, cb) => {
      var results = queryString
        ? queryList.filter(
            (item) =>
              item.toLowerCase().indexOf(queryString.toLowerCase()) === 0
          )
        : queryList;
      cb(results.map(item => {return {value:item}}));
    };

    let listRef = ref(null);
    let page = ref({
      page: computed(() => {
        return Number(route.query.page) || 1;
      }),
      limit: 5,
      total: 0,
    });
    const list = ref([]);

    async function getList(obj: { p?: number; key?: string }) {
      let data = {
        page: obj.p !== undefined ? obj.p : route.query.page || 1,
        limit: page.value.limit,
        key: obj.key !== undefined ? obj.key : route.query.key != 'undefined'?route.query.key:"",
      };
      const loading = ElLoading.service({
        target: listRef.value,
        text: "正在获取信息...",
      });
      const onlineNews = await study_getContent({});
      const wxNews = store.state.wxNews;
      const news = [...onlineNews, ...wxNews].filter(
        (item) => item.title.toLowerCase().indexOf(data.key.toLowerCase()) === 0
      );
      list.value = news
        .sort((a, b) => {
          return (
            new Date(b["created_at"]).valueOf() -
            new Date(a["created_at"]).valueOf()
          );
        })
        .slice((data.page - 1) * data.limit, data.page * data.limit)
        .map((item) => {
          return {
            ...item,
            month: moment(item.created_at).format("YYYY-MM"),
            day: moment(item.created_at).format("DD"),
            text: item.content.replace(/<[^<>]+>/g, ""),
          };
        });
      page.value.total = news.length;
      loading.close();
    }

    function pageChange(p) {
      router.push(`/news?page=${p}&key=${route.query.key}`);
      getList({ p, key: route.query.key });
    }

    function pageSearch() {
      console.log(routeKey);

      router.push(`/news?page=1&key=${routeKey.value}`);
      getList({ p: 1, key: routeKey.value });
    }

    function routeTo(item) {
      if (item.href) {
        window.open(item.href, "_blank");
      } else {
        router.push({ path: `/news/detail`, query: { id: item.id } });
      }
    }

    onMounted(() => {
      getList({});
    });

    return {
      list,
      page,
      route,
      pageChange,
      routeTo,
      listRef,
      routeKey,
      querySearch,
      pageSearch,
    };
  },
});
</script>
<style lang="less" scoped>
.newsListPage {
  text-align: center;
  width: 1200px;
  margin: 0 auto;
  .newsHeader{
    position: relative;
    /deep/ .searchInput{
      position: absolute;
      right: 60px;
      top: 0;
    }
  }
  .newsItem {
    height: 80px;
    padding: 15px 10px;
    display: flex;
    align-items: center;
    &:not(:last-child){
      border-bottom: 1px solid @orangeColor;
    }
    .time {
      width: 70px;
      height: 50px;
      background: linear-gradient(#0076bc, #01245c);
      text-align: center;
      color: white;
      padding: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .day {
        font-size: 17px;
        font-weight: bold;
      }
      .month {
        font-size: 12px;
      }
    }
    .content {
      flex: 1;
      text-align: left;
      padding-left: 15px;
      .title,
      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }
      .title {
        font-size: 22px;
        font-weight: 600;
        &:hover {
          color: @mainColor;
          text-decoration: underline;
        }
      }
      .text {
        color: #999999;
        min-height: 21px;
      }
    }
  }
  .block {
    margin-top: 20px;
    padding-top: 20px;
    display: block;
    text-align: center;
    border-top: 1px dashed @orangeColor;
  }
}
@media (max-width: 600px) {
  .newsListPage {
    max-width: 100vw;
    .content {
      min-width: 0;
    }
    /deep/ .searchInput{
      position: initial !important;
    }
  }
}
</style>